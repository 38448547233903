<template>
  <v-col
    cols="12"
    md="12"
  >
    <!-- <v-row
      no-gutters
      class="pb-4"
    >
      <v-col cols="auto">
        <v-btn
          class="mr-2"
          color="secondary"
        >
          Все
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="actions-bar"
      >
        <v-btn
          color="green"
          @click="createDialog"
        >
          Добавить оценку
        </v-btn>
      </v-col>
    </v-row> -->

    <base-material-card
      color="warning"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">Оценки</div>

        <div class="text-subtitle-1 font-weight-light">
          <h5>Общее количество {{ ratesList.length }}</h5>
        </div>
      </template>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="ratesList"
          :loading="loading"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            <span>
              {{ dateFormatter(item.created_at) }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5"> Оценка </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              v-model="valid"
              ref="form"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.content"
                    label="Контент"
                    :error-messages="errors.content"
                    @input="errors.content = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.client_id"
                    :items="clientsList"
                    item-text="name"
                    item-value="id"
                    label="Клиент"
                    :error-messages="errors.client_id"
                    @change="errors.client_id = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.user_id"
                    :items="usersList"
                    item-text="name"
                    item-value="id"
                    label="Пользователь"
                    :error-messages="errors.user_id"
                    @change="errors.user_id = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.rate"
                    label="Оценка"
                    :error-messages="errors.rate"
                    @input="errors.rate = []"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Отменить
          </v-btn>
          <v-btn
            v-if="actionMethod === 'edit'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="editReview"
          >
            Редактировать
          </v-btn>
          <v-btn
            v-if="actionMethod === 'create'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="createReview"
          >
            Создать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">Вы действительно хотите удалить?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
          >
            Отменить
          </v-btn>
          <v-btn
            color="danger darken-1"
            text
            @click="deleteReview"
          >
            Удалить
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import ReviewsService from '@/app/Services/ReviewsService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'
import GetUsers from '@/app/Helpers/mixins/essences/getUsers'
import GetClients from '@/app/Helpers/mixins/essences/getClients'
import dateFormatter from '@/app/Helpers/dateFormatter'

export default {
  name: 'RatesDashboard',

  mixins: [TableDialogMixin, GetUsers, GetClients],

  data() {
    return {
      headers: [
        {
          sortable: false,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Магазин ID',
          value: 'cart_id',
        },
        {
          sortable: false,
          text: 'Пользователь',
          value: 'user.name',
        },
        {
          sortable: false,
          text: 'Текст',
          value: 'text',
        },
        {
          sortable: true,
          text: 'Оценка',
          value: 'rating',
        },
        {
          sortable: true,
          text: 'Дата',
          value: 'created_at',
        },
        // {
        //   sortable: false,
        //   text: 'Действия',
        //   value: 'actions',
        // },
      ],
      ratesList: [],
      dialogData: {
        content: null,
        client_id: null,
        user_id: null,
        rate: null,
      },
    }
  },

  async created() {
    this.loading = true
    await this.getReviews()
    this.loading = false

    // await this.getUsers()
    // await this.getClients()
  },

  methods: {
    dateFormatter,
    async getReviews() {
      const response = await ReviewsService.getReviews()

      if (response.status) {
        this.ratesList = response.data

        this.$store.dispatch('reviews/setReviewsData', response.data)
      }
    },

    async createReview() {
      if (this.$refs.form.validate()) {
        const response = await ReviewsService.createReview(this.dialogData)

        if (response.status) {
          await this.getReviews()
          this.dialog = false
        } else {
          this.errors = response.error
        }
      }
    },

    async editReview() {
      if (this.$refs.form.validate()) {
        const response = await ReviewsService.editReview(this.selectedId, this.dialogData)

        if (response.status) {
          await this.getReviews()
          this.dialog = false
        } else {
          this.errors = response.error
        }
      }
    },

    async deleteReview() {
      const response = await ReviewsService.deleteReview(this.selectedId)

      if (response.status) {
        await this.getReviews()
        this.dialogDelete = false
      } else {
        this.errors = response.error
      }
    },
  },
}
</script>
