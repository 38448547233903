<script>
import ClientsService from '@/app/Services/ClientsService'

export default {
  data() {
    return {
      clientsList: [],
    }
  },

  methods: {
    async getClients() {
      const response = await ClientsService.getClients()

      if (response.status) {
        this.clientsList = response.data

        this.$store.dispatch('clients/setClientsData', response.data)
      }
    },
  },
}
</script>
